import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { entities, Entity, getEntities } from "../model/entities";
import BoardUX from "./BoardUX";
import Tile from "./Tile";
import { getMaps } from "../data/maps";

type Guess = undefined | true | "maybe" | "harmless";

function getGuesses(stage : number, stageMaps: (Entity|undefined)[][][], knowns: (Entity|null)[][]) {
   
   var maps = stageMaps.map((map, index) => {
       return {
           num: index,
           cells: map,
           uncertain: false,
           missing: map.reduce((acc, row, r: number) => row.reduce((acc, entity: Entity|null|undefined, c: number) => {
            if (!entity) {
               entity = knowns[r][c];
            }
            if (entity) {
               acc[entity] -= 1;
            }
            return acc;
         }, acc), getEntities().reduce((acc, [name, entity]) => {
            acc[name] = entity.count ? entity.count(stage) : 40;
            return acc;
         }, {} as Record<Entity, number>))
       };
   }).filter(map => { 
       return map.cells.every((row, i) => row.every((cell, j) => {
           var known = knowns[i][j];
           return known == null || known == cell || cell == undefined;
       })) && Object.entries(map.missing).every(([name, count]) => count >= 0);
   });

   // If we have a good match on a single map, don't let unpopulated maps interfere
   maps.forEach(map => {
       var knownCells = map.cells.reduce((acc, row) => acc + row.reduce((acc, cell) => acc + (cell === undefined ? 0 : 1), 0), 0);
       if (knownCells < 10) {
           map.uncertain = true;
       }
   });
   var certainMaps = maps.reduce((acc, map) => acc + (map.uncertain ? 0 : 1), 0);
   var numKnowns = knowns.reduce((acc, row) => acc + row.reduce((acc, cell) => acc + (cell === null ? 0 : 1), 0), 0);
   if (certainMaps === 1 && numKnowns >= 6) {
       maps = maps.filter(map => !map.uncertain);
   }

   const solution = (maps.length == 1) ? maps[0].num : undefined;

   var guesses = [];
   for (var i = 0; i < 8; i++) {
       var row = [];
       for (var j = 0; j < 5; j++) {
           var possibleEntities : Partial<Record<Entity, Guess>> = {};
           maps.forEach(map => {
               var entity = map.cells[i][j];
               if (entity === undefined) {
                  // Fill in maybe for question marks
                  (Object.keys(map.missing) as Entity[]).forEach(entity => {
                     if (map.missing[entity] > 0) {
                        if (!possibleEntities[entity]) {

                           // Only match the exit on the top row.
                           if ((entities[entity].row === undefined || entities[entity].row === i) && 
                              (entities[entity].col === undefined || entities[entity].col === j)) {
                              possibleEntities[entity] = "maybe";
                           }
                        }
                     } 
                  });
               } else {
                  if (!possibleEntities[entity] && entity === 'boss') {
                     // Does this boss block any mobs?
                     var harmless = [ [-1, 0], [ 0, -1], [ 1, 0 ], [ 0, 1] ].every(([dx, dy]) => {
                        var r = i + dy;
                        var c = j + dx;
                        var adjacent = r > 0 && r < 8 && c >= 0 && c < 5 && map.cells[r][c];
                        return adjacent !== 'monster' && adjacent !== 'elite' && adjacent !== undefined;
                     }) &&  [ [-1, -1], [ 1, -1], [ -1, 1 ], [ 1, 1] ].every(([dx, dy]) => {
                        var r = i + dy;
                        var c = j + dx;
                        var adjacent = (r==0 || r==7) && (c==0 || c==4) && map.cells[r][c];
                        return adjacent !== 'monster' && adjacent !== 'elite' && adjacent !== undefined;
                     });
                     possibleEntities[entity] = harmless ? "harmless" : true;
                  } else {
                     possibleEntities[entity] = true;
                  }
               }
           });
           row.push(possibleEntities);
       }
       guesses.push(row);
   }

   return { solution, guesses };
}


const Map = () => {
   const navigate = useNavigate();
   const location = useLocation();
   const params = useParams();
   const stage = parseInt(params.stage || "1") || 1;
   const maps = getMaps(stage);


   useEffect(() => { document.title = `Lava Cave Training - Map ${stage}` }, [stage]);

   function onBack() {
      if (location.state?.back) {
         navigate(-1);
       } else {
         navigate("/maps", { replace: true, state: { base: stage > 25 ? 26 : 1 } });
       }
   }

   function initKnowns() {
      var knowns: (Entity|null)[][] = Array.from({ length: 8 }, (_,  row) => Array.from({ length: 5 }, (_, col) => null));
      knowns[7][0] = 'entrance';
      knowns[7][1] = knowns[6][0] = 'empty';
      return knowns;
   }

   const [knowns, setKnowns] = useState(initKnowns);

   function actOnCell(row: number, col: number) {
      var entity = knowns[row][col];

      if (entity == 'entrance') {
         onBack();
      } else {
         var newKnowns = knowns.map(row => row.slice());
         newKnowns[row][col] = null;
         var { guesses } = getGuesses(stage, maps, newKnowns);

         var keys = Object.keys(guesses[row][col]).sort((a,b) => {
               var order = [ "empty", "monster", "elite", "boss", "boulder", "globe", "feather", "map", "hammer", "book", "exit" ];
               return order.indexOf(a) - order.indexOf(b);
         }) as Entity[];

         var i = 0;
         if (entity != null) {
               i = keys.indexOf(entity) + 1;
         }

         newKnowns[row][col] = i == keys.length ? null : keys[i];
         setKnowns(newKnowns);
      }
   }

   function prev() {
      setKnowns(initKnowns());
      navigate(`/maps/${stage - 1}`, { state: { back: location.state?.back }, replace: true });
   }

   function next() {
      setKnowns(initKnowns());
      navigate(`/maps/${stage + 1}`, { state: { back: location.state?.back }, replace: true });
   }

   var { solution, guesses } = getGuesses(stage, maps, knowns);
   
   return <BoardUX>
      {knowns.map((r, row) => r.map((known, col) => {
         if (known) {
            return <Tile key={`${row}-${col}`} className={`row${row} col${col}`} entity={known} onClick={() => actOnCell(row, col)}/>;
         }

         var entities = guesses[row][col];
         if (Object.keys(entities).length == 1) { // single guess
            return <Tile key={`${row}-${col}`} className={`row${row} col${col}`} entity={Object.keys(entities)[0] as Entity} closed={true} reveal={true} onClick={() => actOnCell(row, col)}/>;
         }
         
         var danger = false;
         var entity : Entity|undefined;
         if (entities.boss == true) { 
            entity = "boss";
            danger = true;
         } else if (entities.boulder == true) {
            entity = "boulder";
            danger = true;
         }

         return <Tile key={`${row}-${col}`} className={`row${row} col${col}`} entity={entity} closed={true} danger={danger} available={!danger} onClick={() => actOnCell(row, col)}/>;
      }))}

      <div className="details">
         <div className="solver">
            {solution === undefined ?
                  <div className="text">
                     <div className="line1">Safe and dangerous cells have been identified.</div>
                     <div className="line2">For better results, tell the map what you've found.  Click on a cell until it matches what you saw in-game.</div>
                  </div>
               :
                  <div className="text">
                     <div className="line1">The map has been recognized.</div>
                     <div className="line2">The information you provided is sufficient to identify the map.</div>
                  </div>
            }
            <div className={`stage ${solution !== undefined ? "solved" : ""}`}>
               <div>
                  { stage > 1 ? <><span className="link" onClick={prev}>&lt;</span> </> : <></> }
                  Stage {stage}
                  { stage < 50 ? <> <span className="link" onClick={next}>&gt;</span></> : <></> }
               </div>
               <div>
                  { solution !== undefined ? 
                     <a className="maplink" href={`/img/maps/lavacave-${stage}-${solution + 1}.png`}>Map {solution === undefined ? "?" : solution + 1}</a> :
                     <a className="maplink" href={`/maps/raw/${stage}`}>See Maps</a>
                  }
               </div>
            </div>
         </div>
      </div>
   </BoardUX>;
};
 
export default Map;