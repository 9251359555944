import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import StandardGame from "./StandardGame";
import { Board } from "../model/board";
import { Player } from "../model/player";
import StandardDetails from "./StandardDetails";
import BoardUX from "./BoardUX";
import { generateRandomBoard } from "../data/boardGenerator";
import { Cheats, forEachCheat, toggleCheat } from "../model/cheats";

interface CheatsProps {
   setCheats: (fn: (cheats: Cheats) => Cheats) => void;
}

const CheatsListener = (props : CheatsProps) => {

   var [ text, setText ] = useState("");

   useEffect(() => {
      function onKey(e: KeyboardEvent) {
         setText(text => text + e.key.toLowerCase());
      }

      document.addEventListener("keypress", onKey);
      return () => document.removeEventListener("keypress", onKey);
   }, [setText]);

   useEffect(() => {
      forEachCheat(cheat => {
         if (text.endsWith(cheat)) {
             setText("");
             props.setCheats(cheats => toggleCheat(cheats, cheat));
         }
     });
   }, [props.setCheats, text, setText]);

   
   return <></>;
};
 
export default CheatsListener;
