import { Cheats, hasCheat, mapCheats } from "../model/cheats";

interface CheatsProps {
   cheats: Cheats;
}

const CheatsDisplay = (props : CheatsProps) => {
   var text = mapCheats(cheat => hasCheat(props.cheats, cheat) ? cheat : null).filter(_ => _).join(", ");
   return <>
      {text && <div className="cheats">Cheats enabled: {text}</div>}
   </>;
};
 
export default CheatsDisplay;
