import { Player } from "../model/player";
import { Powerup } from "../model/entities";
import { PropsWithChildren } from "react";

interface StandardDetailProps {
    player: Player;
    label: string;
}

const StandardDetails = (props: PropsWithChildren<StandardDetailProps>) => {
    var powerups: Powerup[] = ["feather", "book", "globe", "hammer", "map"];
    return <div className="details">
        <div className="movepoints">Move Points Used: {props.player.moves}</div>
        <div className="attackpoints">Attack Points Used: {props.player.attacks}</div>
        <div className="powerupsAndLabel">
            <div className="powerups">
                <div className="powerupText">Items owned</div>
                {powerups.map(powerup => <div key={powerup} className={`powerup ${powerup} ${props.player.hasPowerup(powerup) ? "owned": ""}`}></div>)}
            </div>
            <div className="stagelabel">{props.label}</div>
        </div>
    </div>
}

export default StandardDetails;