import { RefObject, useEffect, useRef } from "react";
import { Entity } from "../model/entities";
import MapImage from "./MapImage";
import { useParams } from "react-router-dom";

const RawMaps = () => {
   useEffect(() => { document.title = "Lava Cave Training - Raw Maps"; });

   const params = useParams();
   const stageParam = params.stage;
   const stages = stageParam == "all" ? Array.from({length: 50}).map((_, i)=> i + 1) : [ parseInt(stageParam || "1") || 1 ]; 

   var maps = stages.flatMap(stage => 
      Array.from({length: 5}, (_, map) => 
      ({
         stage: stage,
         map: map,
         ref: useRef<HTMLCanvasElement>(null)
      }))
   );

   async function download() {
      for (var map of maps) {
         var canvas = map.ref.current;
         if (canvas) {
            var dataUri = canvas.toDataURL("image/png");
            var a = document.createElement("a");
            a.download = `lavacave-${map.stage}-${map.map+1}.png`;
            a.href = dataUri;
            a.click();
            await new Promise(r => setTimeout(r, 100));
         }
      }
   }
  
   return <>
      <div className='rawMaps'>
         { maps.map(map => <MapImage stage={map.stage} map={map.map} ref={map.ref} />) }
         <div><button onClick={download}>Download</button></div>
      </div>
   </>;
};
   
export default RawMaps;
