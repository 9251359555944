import { Board, Cell } from "../model/board";
import { Player } from "../model/player";
import { Powerup, entities } from "../model/entities";
import { Cheats, hasCheat } from "../model/cheats";
import { NavigationMap } from "./navigation";

export class GameLogic {
    public static shouldRevealCellContent(cell: Readonly<Cell>, player: Player, cheats: Cheats): boolean {
        return (player.hasPowerup("map") && !!cell.hp) ||
            hasCheat(cheats, "peek");
    }

    public static isCellBlocked(board: Readonly<Board>, cell: Readonly<Cell>, i: number, j: number, map: NavigationMap): boolean {
        if (cell.entity == 'exit' && cell.open) {
            // The exit is blocked while boss is alive
            return board.findEntity('boss') ? true : false;
        } else {
            // Closed cells are blocked by nearby mobs and boulders
            return map.blocked(i, j) && !cell.open;
        }
    }

    public static actOnCell(board: Board, cell: Readonly<Cell>, row: number, col: number, player: Player, cheats: Cheats, onComplete: () => void) {
        var map = new NavigationMap(board, player);
        if (map.available(row, col) || hasCheat(cheats, "force")) {
            if (!cell.open) {
                this.openCell(board, row, col, player, cheats);
            } else if (cell.hp) {
                this.attackCell(board, cell, row, col, player, cheats);
            } else if (entities[cell.entity].powerup) {
                this.takePowerup(board, cell, row, col, player);
            } else if (cell.entity === 'exit') {
                this.tryExit(board, player, cheats, onComplete);
            }
        }
    }

    private static openCell(board: Board, row: number, col: number, player: Player, cheats: Cheats) {
        player.moves++;
        board.updateCell(row, col, cell => cell.open = true);
    }

    private static attackCell(board: Board, cell: Readonly<Cell>, row: number, col: number, player: Player, cheats: Cheats) {
        if (entities[cell.entity].cost === 'movement') {
            player.moves++;
        } else if (entities[cell.entity].cost === 'attack') {
            player.attacks++;
        }
        GameLogic.decrementHP(board, row, col, hasCheat(cheats, "gib") ? cell.hp : 1);
    }

    private static decrementHP(board: Board, row: number, col: number, amount: number) {
        var bossDamage : number | undefined;
        board.updateCell(row, col, cell => {
            cell.hp! -= amount;
            if (cell.hp! <= 0) {
                var entity = cell.entity;
                cell.clear(cell.open);
                bossDamage = entities[entity].bossDamage;
            }
        });

        if (bossDamage) {
            var pos = board.findEntityPosition('boss');
            if (pos) {
                this.decrementHP(board, pos.row, pos.col, bossDamage);
            }
        }
    }

    private static takePowerup(board: Board, cell: Readonly<Cell>, row: number, col: number, player: Player) {
        if (cell.entity === "globe"){
            var exit = board.findEntityPosition("exit");
            if (exit) {
                board.updateCell(exit.row, exit.col, cell => cell.open = true);
            }
        }
        player.setPowerup(cell.entity as Powerup, true);
        board.updateCell(row, col, cell => cell.clear(true));
    }

    private static tryExit(board: Board, player: Player, cheats: Cheats, onComplete: () => void) {
        if (hasCheat(cheats, "force") || !board.findEntity('boss')) {
            onComplete();
        }
    }
}
