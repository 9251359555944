import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { puzzles } from "../data/puzzles";
import Tile from "./Tile";
import { Entity } from "../model/entities";
import BoardUX from "./BoardUX";

const grid: (number | Entity)[][] = [
   ["boulder", "exit", "boulder", "boulder", "boulder" ],
   ["boulder", 15, 14, 13, "boulder" ],
   ["boulder", "boulder", "boulder", 12, "boulder" ],
   ["boulder", 9, 10, 11, "boulder" ],
   ["boulder", 8, "boulder", "boulder", "boulder" ],
   ["boulder", 7, 6,  5, "boulder" ],
   ["boulder", "boulder", "boulder", 4, "boulder" ],
   ["entrance", 1, 2, 3, "boulder" ],
];

interface PuzzleListProps {
   current: number;
}

const PuzzleList = (props: PuzzleListProps) => {

   var location = useLocation();
   var navigate = useNavigate();

   useEffect(() => { document.title = `Lava Cave Training - Puzzles` });

   function goToPuzzle(puzzle: number) {
      if (puzzle <= props.current) {
         navigate(`/puzzles/${puzzle}`, { state: { back: true } });
      }
   }

   function goBack() {
      if (location.state?.back) {
         navigate(-1);
      } else {
         navigate("/", { replace: true });
      }
   }

   return <BoardUX>
      {grid.map((r, row) => r.map((item, col) => {
         if (item === "exit") {
            return <Tile 
               key={`${row}-${col}`} 
               className={`row${row} col${col}`} 
               entity={item}
               closed={props.current < puzzles.length}
               barText={"You win!"}
               barWidth={1}
               onClick={goBack}
            />;
         } else if (item == "entrance") {
            return <Tile 
               key={`${row}-${col}`} 
               className={`row${row} col${col}`} 
               entity={item}
               onClick={goBack}
            />;
         } else if (typeof item == "string") {
            return <Tile 
               key={`${row}-${col}`} 
               className={`row${row} col${col}`} 
               entity={item} 
            />;
         } else {
            var puzzle = puzzles[item];
            return <Tile 
                key = {`${row}-${col}`}
                className = {`row${row} col${col}`}
                closed = {item > props.current} 
                entity = {puzzle.type ?? "monster"}
                fade = {item < props.current}
                barWidth = {item >= props.current ? 1 : 0}
                barText = {`Puzzle ${item}`}
                onClick={() => goToPuzzle(item)}
            />;
         }
      }))}
      <div className="details">
         <div className="selectPuzzle">
            <div className="line1">Select a puzzle and let the challenge begin.</div>
            <div className="line2">In these puzzles, your goal is to beat the boss and exit, spending as few attack points as possible and without wasting any excess movement points.</div>
         </div>
      </div>
   </BoardUX>;
};
 
export default PuzzleList;
