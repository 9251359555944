// peek lets you see the contents of a cell before opening it, like a superpowered version of the map
// force lets you open cells even if the rules don't allow that
// gib lets you kill monsters and boulders with a single click

import { useState } from "react";

export type Cheat = "peek" | "force" | "gib";

export type Cheats = Partial<Record<Cheat, boolean>>;

const cheatlist: Cheat[] = ["peek", "force", "gib"];

export function forEachCheat(callback: (cheat: Cheat) => void) {
    cheatlist.forEach(callback);
}

export function mapCheats<T>(callback: (cheat: Cheat) => T): T[] {
    return cheatlist.map(callback);
}

export function hasCheat(cheats: Cheats, cheat: Cheat) {
    return cheats[cheat] ?? false;
}

export function toggleCheat(cheats: Cheats, cheat: Cheat): Cheats {
    var clone = { ...cheats };
    clone [cheat] = !clone [cheat];
    return clone ;
}