// how many monsters, elites, boulders?
// frequency of powerups?

export type Powerup = "map" | "globe" | "feather" | "hammer" | "book";
export type Entity = "entrance" | "exit" | "monster" | "elite" | "boss" | "boulder" | Powerup | "empty";
export type EntityDefinition = {
   count?: (stage: number) => number,
   row?: number,
   col?: number,
   open?: boolean,
   hp?: (floor: number) => number,
   bossDamage?: number,
   cost?: 'attack' | 'movement',
   powerup?: boolean
}

const powerups = generatePowerups();

export const entities: Record<Entity, EntityDefinition> = {
   entrance: {
      count: () => 1,
      row: 7,
      col: 0,
      open: true
   },
   exit: {
      count: () => 1,
      row: 0
   },
   monster: {
      count: (stage) => 12 - Math.floor((stage-1)/8) + Math.floor((stage-1)/10),
      hp: () => 2,
      bossDamage: 4,
      cost: 'attack'
   },
   elite: {
      count: (stage) => 3 + Math.floor((stage-1)/8),
      hp: () => 5,
      bossDamage: 15,
      cost: 'attack'
   },
   boss: {
      count: () => 1,
      hp: (floor) => 93 +
         floor * 2 +
         Math.floor((floor - 1) / 10) * 4 + 
         Math.max(Math.floor((floor - 2) / 8), 0) * 11,
      cost: 'attack'
   },
   boulder: {
      count: (stage) => 5 - Math.floor((stage-1)/25),
      hp: () => 15,
      cost: 'movement'
   },
   map: {
      count: (stage) => powerups[(stage-1)%25].includes("map") ? 1 : 0,
      powerup: true
   },
   globe: {
      count: (stage) => powerups[(stage-1)%25].includes("globe") ? 1 : 0,
      powerup: true
   },
   feather: {
      count: (stage) => powerups[(stage-1)%25].includes("feather") ? 1 : 0,
      powerup: true
   },
   hammer: {
      count: (stage) => powerups[(stage-1)%25].includes("hammer") ? 1 : 0,
      powerup: true
   },
   book: {
      count: (stage) => powerups[(stage-1)%25].includes("book") ? 1 : 0,
      powerup: true
   },
   empty: {}
};

export function getEntities() : [Entity, EntityDefinition][] {
   return Object.entries(entities) as [Entity, EntityDefinition][];
}

function generatePowerups() : Entity[][] {
   const list : Entity[] = [
      "hammer",
      "feather",
      "map",
      "globe",
      "book"
   ];

   var result : Entity[][] = [];
   // First 5 levels are one powerup each
   list.forEach(e => result.push([e]));

   // Second 5 levels are two powerups
   for (var i = 0; i < list.length; i++) {
      for (var j = i+1; j < list.length; j++) {
         result.push([list[i], list[j]]);
      }
   }

   // Second 5 levels are three powerups
   for (var i = 0; i < list.length; i++) {
      for (var j = i+1; j < list.length; j++) {
         result.push(list.filter(e => e !== list[i] && e !== list[j]));
      }
   }

   return result;
}

