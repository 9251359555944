import { Board } from "../model/board";
import { Cheats } from "../model/cheats";
import { Player } from "../model/player";
import { GameLogic } from "../logic/game";
import { NavigationMap } from "../logic/navigation";
import Tile from "./Tile";

interface StandardGameProps {
    board: Board;
    player: Player;
    cheats: Cheats;
    onBack: () => void;
    onComplete: () => void;
    actOnCell?: (board: Board, row: number, col: number, defaultAction: ()=> void) => void;
}

const StandardGame = (props: StandardGameProps) => {

    var map = new NavigationMap(props.board, props.player);


    function onClick(board: Board, row: number, col: number) {
        var defaultAction = () => {
            var cell = board.cell(row, col);

            if (cell.entity == 'entrance') {
                props.onBack();
            } else {
                GameLogic.actOnCell(board, cell, row, col, props.player, props.cheats, () => props.onComplete());
            }
        };

        if (props.actOnCell) {
            props.actOnCell(board, row, col, defaultAction);
        } else {
            defaultAction();
        }
    }

    
    return <>
        {props.board.map((cell, row, col) => {
            var reveal = GameLogic.shouldRevealCellContent(cell, props.player, props.cheats);
            var visible = cell.open || reveal;

            return <Tile 
                key = {`${row}-${col}`}
                className = {`row${row} col${col}`}
                closed = {!cell.open} 
                reveal = {!cell.open && reveal}
                available = {map.available(row, col) && !cell.open} 
                entity = {visible ? cell.entity : undefined}
                blocked = {GameLogic.isCellBlocked(props.board, cell, row, col, map)}
                barWidth = {visible && cell.hp ? cell.hp / cell.maxHP : undefined }
                barText = {visible && cell.hp ? `${cell.hp} / ${cell.maxHP}` : undefined }
                onClick={() => onClick(props.board, row, col)}
            />
        })}
    </>;
}
 
export default StandardGame;