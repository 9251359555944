import { Player } from "../model/player";
import { Board } from "../model/board";
import { entities } from "../model/entities";

export type NavigationStatus = "available" | "blocked" | undefined;

export class NavigationMap {
    private _map: NavigationStatus[][];

    constructor(board: Board, player: Player) {
        var map = board.map((): NavigationStatus => undefined);
        var explored = board.map(() => false);

        // Block any cells next to an incident
        board.forEachCell((cell, row, col) => {
            if (cell.open && cell.hp) {
                board.forEachAdjacentCell(row, col, (adjCell, adjRow, adjCol) => {
                    if (!adjCell.open) {
                        map[adjRow][adjCol] = "blocked";
                    }
                });
            }
        });

        var examine: { row: number; col: number; }[] = [];
        examine.push({ row: entities.entrance.row!, col: entities.entrance.col! });
        while (examine.length > 0) {
            var { row, col } = examine.pop()!;

            var cell = board.cell(row, col);
            if (!map[row][col]) {
                map[row][col] = "available";
            }
            if (!explored[row][col]) {
                explored[row][col] = true;
                if (cell.open && !cell.hp) {
                    board.forEachAdjacentCell(row, col, (_, adjRow, adjCol) => {
                        examine.push({ row: adjRow, col: adjCol });
                    });
                    if (player.hasPowerup("feather")) {
                        board.forEachDiagonalCell(row, col, (_, adjRow, adjCol) => {
                            if (!map[adjRow][adjCol]) {
                                map[adjRow][adjCol] = "available";
                            }
                        });
                    }
                }
            }
        }

        this._map = map;
    }

    public blocked(row: number, col: number) {
        return this._map[row][col] === "blocked";
    }

    public available(row: number, col: number) {
        return this._map[row][col] === "available";
    }
}
