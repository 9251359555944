import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import StandardGame from "./StandardGame";
import { Board } from "../model/board";
import { Player } from "../model/player";
import StandardDetails from "./StandardDetails";
import BoardUX from "./BoardUX";
import { generateRandomBoard } from "../data/boardGenerator";
import { Cheats } from "../model/cheats";

interface FreePlayLevelProps {
   floor: number;
   cheats: Cheats;
   onComplete: () => void;
   onBack: () => void;
}

const FreePlayLevel = (props : FreePlayLevelProps) => {
   var player = Player.useState();

   var board = Board.useState(() => generateRandomBoard(props.floor));

   return <BoardUX cheats={props.cheats}>
      <StandardGame player={player} board={board} cheats={props.cheats} onComplete={props.onComplete} onBack={props.onBack} />
      <StandardDetails player={player} label={`Stage ${props.floor}`} />
   </BoardUX>;
};
 
interface FreePlayProps {
   cheats: Cheats;
}

const FreePlay = (props: FreePlayProps) => {
   const navigate = useNavigate();
   const location = useLocation();
   var [floor, setFloor] = useState(1);

   useEffect(() => { document.title = `Lava Cave Training - Free Play` });

   function onComplete() {
      setFloor(floor + 1);
   }

   function onBack() {
      location.state?.back ? navigate(-1) : navigate("/", { replace: true });
   }

   return <FreePlayLevel floor={floor} key={floor} cheats={props.cheats} onComplete={onComplete} onBack={onBack} />;
};

export default FreePlay;
