import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import StandardGame from "./StandardGame";
import { Board, Cell } from "../model/board";
import { puzzles } from "../data/puzzles";
import { entities } from "../model/entities";
import { Player } from "../model/player";
import StandardDetails from "./StandardDetails";
import BoardUX from "./BoardUX";
import { Cheats } from "../model/cheats";
import Tile from "./Tile";

interface PuzzleProps {
   cheats: Cheats;
   onComplete: (puzzle: number) => void;
}

const Puzzle = (props: PuzzleProps) => {
   const navigate = useNavigate();
   const location = useLocation();
   const params = useParams();
   const puzzle = parseInt(params.puzzle || "1") || 1;

   var [ done, setDone ] = useState(false);
   useEffect(() => { document.title = `Lava Cave Training - Puzzle ${puzzle}` }, [puzzle]);

   var def = puzzles[puzzle];
   
   function loadBoard() {
      return def.cells.map(row => row.map(c => {
         var cell = new Cell();
         cell.entity = c.e ?? "empty";
         cell.open = c.o != 0;
         if (entities[cell.entity].hp) {
               cell.maxHP = c.h ?? entities[cell.entity].hp!(1);
               cell.hp = c.c ?? cell.maxHP;
         }
         return cell;
      }));
   }

   var player = Player.useState()
   var board = Board.useState(loadBoard);
   
   function onBack() {
      if (location.state?.back) {
         navigate(-1);
       } else {
         navigate("/puzzles", { replace: true });
       }
   }

   function onComplete() {
      if (player.attacks <= def.attacks && player.moves <= def.moves) {
         props.onComplete(puzzle);
      }
      setDone(true);
   }

   function onReset() {
      board.updateCells(loadBoard())
      player.clear();
      setDone(false);
   }

   function actOnCell(board: Board, row: number, col: number, defaultAction: () => void) {
      if (def.special === "bossswap") {
         var bossPosition = board.findEntityPosition("boss");
         if (bossPosition) { // If there's a boss
            var bossCell = board.cell(bossPosition.row, bossPosition.col);
            if (!bossCell.open) { // And he's not revealed yet
               var cell = board.cell(row, col);
               if (!cell.open) { // And you just clicked on a closed cell
                  var temp = cell.clone();
                  board.updateCell(row, col, cell => cell.copy(bossCell));
                  board.updateCell(bossPosition.row, bossPosition.col, cell => cell.copy(temp));
               }
            }
         }
      }

      defaultAction();
   }

   return <BoardUX cheats={props.cheats}>
      <StandardGame player={player} board={board} cheats={props.cheats} actOnCell={actOnCell} onComplete={onComplete} onBack={onBack} />
      <StandardDetails player={player} label={`Puzzle ${puzzle}`}/>
      {done && <div className="overlay">
         {(player.attacks <= def.attacks && player.moves <= def.moves) ?
            <div className="victory">
               <div className="image"/>
               <div className="attacks"><span className="result"/>Attack points used: {player.attacks}</div>
               <div className="moves"><span className="result"/>Movement points used: {player.moves}</div>
               <div className="buttons">
                  <Tile className="button" entity="exit" barWidth={1} barText="Continue" onClick={onBack}/>
               </div>
            </div> : <div className="defeat">
               <div className="image"></div>
               <div className="attacks"><span className={`result ${player.attacks > def.attacks ? "fail" : ""}`}/>Attack points used: {player.attacks}</div>
               <div className="moves"><span className={`result ${player.moves> def.moves ? "fail" : ""}`}/>Movement points used: {player.moves}</div>
               <div className="buttons">
                  <Tile className="button" entity="entrance" barWidth={1} barText="Back" onClick={onBack}/>
                  <Tile className="button" entity="monster" barWidth={1} barText="Try Again" onClick={onReset}/>
               </div>
            </div>}
         </div>}
   </BoardUX>;
};
 
export default Puzzle;