import { ReactNode } from "react";
import { Cheats } from "../model/cheats";
import CheatsDisplay from "./CheatsDisplay";

const BoardUX = (props: { cheats?: Cheats; children?: ReactNode; }) => {
   return <div className="sizer">
      <div className="board">
         {props.cheats && <CheatsDisplay cheats={props.cheats}/>}
         {props.children}
      </div>
   </div>;
};
 
export default BoardUX;