import { Entity } from "../model/entities";

export type SerializedCell = { e?: Entity; o?: number; h?: number; c?: number; };
export type Puzzle = { attacks: number; moves: number; hint?: string; cells: SerializedCell[][]; special?: "bossswap"; type?: Entity; id: string };
export const puzzles: Puzzle[] = [
    { attacks: 0, moves: 0, hint: "", cells: [], id: "none" },

    //
    // Basic damage math
    //
    // Normal monster does 4
    {
        attacks: 2,
        moves: 0,
        id: "normal",
        cells: [[{},{},{"e":"exit"},{},{}],[{},{},{},{},{}],[{},{},{"e":"boss","h":100,"c":4},{},{}],[{},{},{},{},{}],[{},{},{},{"e":"monster"},{}],[{},{},{},{},{}],[{},{},{},{},{}],[{"e":"entrance"},{},{},{},{}]]
    },

    // Elite monster does 15
    {
        attacks: 5,
        moves: 0,
        id: "elite",
        cells: [[{},{},{"e":"exit"},{},{}],[{},{},{},{},{}],[{},{},{"e":"boss","h":100,"c":15},{},{}],[{},{},{},{},{}],[{},{"e":"elite"},{},{},{}],[{},{},{},{},{}],[{},{},{},{},{}],[{"e":"entrance"},{},{},{},{}]]
    },

    // Elite > normal
    {
        attacks: 5,
        moves: 0,
        id: "chooseelite",
        cells: [[{},{},{"e":"exit"},{},{}],[{},{},{},{},{}],[{},{},{"e":"boss","h":100,"c":12},{},{}],[{},{},{},{},{}],[{"e":"elite"},{"e":"elite"},{},{"e":"monster"},{"e":"monster"}],[{"e":"elite"},{"e":"elite"},{},{"e":"monster"},{"e":"monster"}],[{},{},{},{},{}],[{"e":"entrance"},{},{},{},{}]]    
    },

    // Normal > elite if the boss is low
    {
        attacks: 4,
        moves: 0,
        id: "choosenormal",
        cells: [[{},{},{"e":"exit"},{},{}],[{},{},{},{},{}],[{},{},{"e":"boss","h":100,"c":8},{},{}],[{},{},{},{},{}],[{"e":"elite"},{"e":"elite"},{},{"e":"monster"},{"e":"monster"}],[{"e":"elite"},{"e":"elite"},{},{"e":"monster"},{"e":"monster"}],[{},{},{},{},{}],[{"e":"entrance"},{},{},{},{}]],
    },

    // Sometimes just finish the boss
    {
        attacks: 8,
        moves: 0,
        id: "finishboss",
        cells: [[{},{},{"e":"exit"},{},{}],[{},{},{},{},{}],[{},{},{"e":"boss","h":100,"c":20},{},{}],[{},{},{},{},{}],[{"e":"elite"},{"e":"elite"},{},{"e":"monster"},{"e":"monster"}],[{"e":"elite"},{"e":"elite"},{},{"e":"monster"},{"e":"monster"}],[{},{},{},{},{}],[{"e":"entrance"},{},{},{},{}]],
        type: "elite"
    },

    //
    // Mapping
    //

    // Kill the boss
    {
        attacks: 4,
        moves: 6,
        id: "mapboss",
        cells: [[{"o":0},{"o":0},{"e":"exit"},{"o":0},{"o":0}],[{"o":0},{"o":0},{"o":0},{"o":0},{"o":0}],[{"o":0},{"o":0},{"e":"boss","h":100,"c":4,"o":0},{"o":0},{"o":0}],[{"o":0},{"o":0},{"o":0},{"o":0},{"o":0}],[{"o":0},{"o":0},{"o":0},{"o":0},{"o":0}],[{"o":0},{"o":0},{"o":0},{"o":0},{"o":0}],[{"o":0},{"o":0},{"o":0},{"o":0},{"o":0}],[{"e":"entrance"},{},{"e":"map"},{},{}]]
    },

    // Kill the monster and the boss dies
    {
        attacks: 2,
        moves: 6,
        id: "mapmonster",
        cells: [[{"o":0},{"o":0},{"e":"exit"},{"o":0},{"o":0}],[{"o":0},{"o":0},{"o":0},{"o":0},{"o":0}],[{"o":0},{"o":0},{"e":"boss","h":100,"c":4,"o":0},{"o":0},{"o":0}],[{"o":0},{"o":0},{"o":0},{"o":0},{"o":0}],[{"o":0},{"o":0},{"e":"monster","o":0},{"o":0},{"o":0}],[{"o":0},{"o":0},{"o":0},{"o":0},{"o":0}],[{"o":0},{"o":0},{"o":0},{"o":0},{"o":0}],[{"e":"entrance"},{},{"e":"map"},{},{}]]
    },
    
    // Avoid the boss to kill the monster
    {
        attacks: 2,
        moves: 7,
        id: "mapdodge",
        cells: [[{"o":0},{"o":0},{"e":"exit"},{"o":0},{"o":0}],[{"o":0},{"o":0},{"o":0},{"o":0},{"o":0}],[{"o":0},{"o":0},{"e":"monster","o":0},{"o":0},{"o":0}],[{"o":0},{"o":0},{"o":0},{"o":0},{"o":0}],[{"o":0},{"o":0},{"e":"boss","h":100,"c":4,"o":0},{"o":0},{"o":0}],[{"o":0},{"o":0},{"o":0},{"o":0},{"o":0}],[{"o":0},{"o":0},{"o":0},{"o":0},{"o":0}],[{"e":"entrance"},{},{"e":"map"},{},{}]],
    },

    // Kill lots of monsters
    {
        attacks: 30,
        moves: 15,
        id: "killthemall",
        cells: [[{"e":"boulder","o":0},{"e":"boulder","o":0},{"e":"exit"},{"e":"boulder","o":0},{"e":"boulder","o":0}],[{"e":"elite","o":0},{"e":"boulder","o":0},{"o":0},{"e":"monster","o":0},{"e":"elite","o":0}],[{"o":0},{"e":"monster","o":0},{"o":0},{"o":0},{"e":"boulder","o":0}],[{"e":"monster","o":0},{"o":0},{"o":0},{"e":"boulder","o":0},{"e":"elite","o":0}],[{"e":"elite","o":0},{"e":"boulder","o":0},{"o":0},{"o":0},{"e":"monster","o":0}],[{"e":"boulder","o":0},{"e":"boulder","o":0},{"o":0},{"e":"boulder","o":0},{"e":"boulder","o":0}],[{},{},{},{},{}],[{"e":"entrance"},{},{"e":"map"},{},{"e":"boss","h":100,"c":78}]],
    },

    // Easy maze.  Don't touch the boss
    {
        attacks: 18,
        moves: 21,
        id: "mapwind",
        cells: [[{"e":"boulder","o":0},{"e":"boulder","o":0},{"e":"exit"},{"e":"boulder","o":0},{"e":"monster","o":0}],[{"e":"monster","o":0},{"o":0},{"o":0},{"e":"monster","o":0},{"o":0}],[{"o":0},{"e":"boulder","o":0},{"e":"monster","o":0},{"e":"boulder","o":0},{"o":0}],[{"o":0},{"e":"monster","o":0},{"e":"boss","h":100,"c":36,"o":0},{"e":"monster","o":0},{"o":0}],[{"o":0},{"e":"boulder","o":0},{"e":"monster","o":0},{"o":0},{"e":"boulder","o":0}],[{"e":"monster","o":0},{"e":"boulder","o":0},{"o":0},{"e":"boulder","o":0},{"e":"monster","o":0}],[{"e":"boulder","o":0},{"e":"boulder","o":0},{"o":0},{"e":"boulder","o":0},{"o":0}],[{"e":"entrance"},{"e":"map"},{},{},{}]]
    },

    // Avoid the boulders too
    {
        attacks: 4,
        moves: 17,
        id: "mapboulders",
        type: "elite",
        cells: [[{ "e": "monster", "o": 0 }, { "o": 0 }, { "o": 0 }, { "e": "boulder", "o": 0 }, { "e": "exit" }], [{ "o": 0 }, { "e": "boulder", "o": 0 }, { "o": 0 }, { "o": 0 }, { "o": 0 }], [{ "o": 0 }, { "e": "elite", "o": 0 }, { "o": 0 }, { "e": "boulder", "o": 0 }, { "e": "boulder", "o": 0 }], [{ "o": 0 }, { "e": "boulder", "o": 0 }, { "o": 0 }, { "o": 0 }, { "o": 0 }], [{ "o": 0 }, { "e": "boulder", "o": 0 }, { "e": "boulder", "o": 0 }, { "e": "boulder", "o": 0 }, { "o": 0 }], [{ "e": "boss", "h": 100, "c": 6, "o": 0 }, { "o": 0 }, { "o": 0 }, { "o": 0 }, { "o": 0 }], [{ "o": 0 }, { "o": 0 }, { "e": "boulder", "o": 0 }, { "e": "boulder", "o": 0 }, { "e": "boulder", "o": 0 }], [{ "e": "entrance" }, { "e": "map" }, {}, {}, {}]]
    },

    // You got to make it to the door
    {
        id: "rushthedoor",
        attacks: 9,
        moves: 9,
        cells: [[{"o":0},{"o":0},{"e":"exit"},{"o":0},{"e":"elite","o":0}],[{"e":"boulder","o":0},{"e":"monster","o":0},{"e":"elite","o":0},{"e":"boulder","o":0},{"o":0}],[{"e":"monster","o":0},{"e":"monster","o":0},{"e":"boulder","o":0},{"o":0},{"o":0}],[{"o":0},{"o":0},{"o":0},{"o":0},{"e":"boulder","o":0}],[{"e":"boulder","o":0},{"e":"monster","o":0},{"o":0},{"o":0},{"o":0}],[{"e":"boulder","o":0},{"o":0},{"o":0},{"e":"monster","o":0},{"e":"elite","o":0}],[{"e":"elite","o":0},{"o":0},{"e":"monster","o":0},{"o":0},{"o":0}],[{"e":"entrance"},{},{},{"e":"map"},{"e":"boss","h":100,"c":23}]],
        type: "elite"
    },

    // 
    // Special treats
    //

    // Boss swapper - open the tile with the fewest adjacent tiles
    {
        attacks: 20,
        moves: 5,
        special: "bossswap",
        id: "bossswap",
        cells: [[{}, {}, { "e": "exit" }, {}, {}], [{}, {}, {}, {}, {}], [{}, {}, {}, {}, {}], [{}, {}, {}, {}, { "e": "boulder" }], [{}, {}, {}, { "e": "elite", "o": 0 }, { "e": "elite", "o": 0 }], [{}, {}, {}, { "e": "elite", "o": 0 }, { "e": "elite", "o": 0 }], [{}, {}, {}, { "e": "elite", "o": 0 }, { "e": "elite", "o": 0 }], [{ "e": "entrance" }, {}, { "e": "boulder" }, { "e": "elite", "o": 0 }, { "e": "boss", "h": 100, "c": 60, "o": 0 }]],
        type: "elite"
    },

    // Too many monsters
    {
        attacks: 14,
        moves: 9,
        id: "manymonsters",
        type: "boss",
        cells: [[{"e":"monster","o":0},{"e":"boulder","o":0},{"e":"exit"},{"e":"boulder","o":0},{"e":"monster","o":0}],[{"o":0},{"o":0},{"o":0},{"e":"monster","o":0},{"e":"boulder","o":0}],[{"e":"elite","o":0},{"o":0},{"o":0},{"o":0},{"o":0}],[{"o":0},{"e":"boss","h":100,"c":32},{"o":0},{"o":0},{"o":0}],[{"e":"elite","o":0},{"o":0},{},{"e":"monster"},{"e":"monster","o":0}],[{"e":"boulder","o":0},{"e":"monster"},{},{"e":"boulder","o":0},{"e":"elite","o":0}],[{"o":0},{"o":0},{},{"e":"monster"},{"e":"boulder","o":0}],[{"e":"entrance"},{},{},{},{"e":"map"}]],
    },
    
    
    // Trick - run through the boulders to save attacks
    {
        attacks: 6,
        moves: 60,
        id: "boulderdash",
        cells: [[{"e":"boulder"},{},{"e":"exit"},{},{"e":"boulder"}],[{"e":"boulder"},{},{"e":"boulder"},{},{"e":"boulder"}],[{"e":"boulder"},{"e":"elite"},{"e":"boulder"},{"e":"monster"},{"e":"boulder"}],[{"e":"boulder"},{"e":"elite"},{"e":"boulder"},{"e":"monster"},{"e":"boulder"}],[{"e":"boulder"},{"e":"elite"},{"e":"boulder"},{"e":"monster"},{"e":"boulder"}],[{"e":"boulder"},{"e":"elite"},{"e":"boulder"},{"e":"monster"},{"e":"boulder"}],[{},{},{},{},{}],[{"e":"entrance"},{},{"e":"boss","h":100,"c":16},{},{}]],
        type: "boss"
    },
];

