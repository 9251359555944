import { useState } from "react";
import { Powerup } from "./entities";


type PlayerState = {
    attacks: number;
    moves: number;
    powerups: Partial<Record<Powerup, boolean | undefined>>;
};

export class Player {
    private _state : Readonly<PlayerState>;
    private _setState: (state: PlayerState) => void;


    public static useState() : Player {
        var [state, setState] = useState({ attacks: 0, moves: 0, powerups: {} });
        return new Player(state, setState);
    }

    private constructor(state: PlayerState, setState: (state: PlayerState) => void) {
        this._state = state;
        this._setState = setState;
    }

    private update(fn: (state: PlayerState) => void) {
        var state : PlayerState = { 
            attacks: this._state.attacks,
            moves: this._state.moves,
            powerups: { ...this._state.powerups } 
        };
        fn(state);
        this._setState(state);
        this._state = state;
    }

    public clear() {
        this.update(state => {
            state.attacks = 0;
            state.moves = 0;
            state.powerups = {};
        });
    }

    public get attacks() { return this._state.attacks; }
    public get moves() { return this._state.moves; }
    public set attacks(value: number) { this.update(state => state.attacks = value); }
    public set moves(value: number) { this.update(state => state.moves = value); }

    public hasPowerup(powerup: Powerup): boolean { return this._state.powerups[powerup] ?? false; }
    public setPowerup(powerup: Powerup, value: boolean) { this.update(state => state.powerups[powerup] = value); }
    public forEachPowerup(callback: (powerup: Powerup) => void) {
        for (var key in this._state.powerups) {
            var powerup = key as Powerup;
            if (this._state.powerups[powerup]) {
                callback(powerup);
            }
        }
    }
}
