import { Routes, Route } from 'react-router-dom';
import CheatsListener from './CheatsListener';
import Menu from './Menu';
import PuzzleList from './PuzzleList';
import Puzzle from './Puzzle';
import FreePlay from './FreePlay';
import MapList from './MapList';
import Map from './Map';
import { useState } from 'react';
import RawMaps from './RawMaps';
import Analysis from './Analysis';
import KadaverCheck from './KadaverCheck';
 
const App = () => {

   function getLevel() {
      return parseInt(localStorage.getItem("puzzle2") ?? "1");
   }

   var [cheats, setCheats] = useState({});
   var [puzzleLevel, setPuzzleLevel] = useState(() => getLevel());

   function onPuzzleComplete(level: number) {
      if (level >= puzzleLevel) {
         var newLevel = level + 1;
         localStorage.setItem("puzzle2", newLevel.toString());
         setPuzzleLevel(newLevel); // TODO: Do I care if you hack the URL to cheat?
      }
   }

   return (
      <>
         <CheatsListener setCheats={setCheats}/>
         <Routes>
            <Route path="/" element={<Menu />} />
            <Route path="/puzzles" element={<PuzzleList current={puzzleLevel}/>} />
            <Route path="/puzzles/:puzzle" element={<Puzzle cheats={cheats} onComplete={onPuzzleComplete}/>} />
            <Route path="/freeplay" element={<FreePlay cheats={cheats}/>} />
            <Route path="/maps" element={<MapList/>} />
            <Route path="/maps/raw/:stage" element={<RawMaps/>} />
            <Route path="/maps/:stage" element={<Map/>} />
            <Route path="/analyze" element={<Analysis/>} />
            <Route path="/kadaver" element={<KadaverCheck/>} />
         </Routes>
      </>
   );
};
 
export default App;
