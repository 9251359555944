import { useState } from "react";
import { Entity } from "./entities";

export class Cell {
    private _open = false;
    private _entity: Entity = "empty";
    private _hp = 0;
    private _maxHP = 0;

    public copy(other: Readonly<Cell>) {
        this.open = other.open;
        this.entity = other.entity;
        this.hp = other.hp;
        this.maxHP = other.maxHP;
    }

    public clone() { 
        var cell = new Cell();
        cell.copy(this);
        return cell;
    }

    public get open() { return this._open; }
    public set open(value: boolean) { this._open = value; }

    public get entity() { return this._entity; }
    public set entity(value: Entity) { this._entity = value; }

    public get hp() { return this._hp; }
    public set hp(value: number) { this._hp = value; }

    public get maxHP() { return this._maxHP; }
    public set maxHP(value: number) { this._maxHP = value; }

    public clear(open: boolean) {
        this._open = open;
        this._entity = "empty";
        this._hp = 0;
        this._maxHP = 0;
    }
}

export class Board {
    private static _rows = 8;
    private static _cols = 5;

    private _cells: Readonly<Cell[][]>;
    private _setCells: (cells: Cell[][]) => void;

    public static useState(init: () => Cell[][]) : Board {
        var [cells, setCells] = useState(init);
        return new Board(cells, setCells);
    }

    private constructor(cells: Cell[][], setCells: (cells: Cell[][]) => void) {
        this._cells = cells;
        this._setCells = setCells;
    }

    private update(fn: (cells: Cell[][]) => void) {
        var cells = this._cells.map(row => row.map(cell => cell.clone()));
        fn(cells);
        this._setCells(cells);
        this._cells = cells;
    }

    public clear() {
        this.update(cells => {
            this._forEachCell(cells, cell => cell.clear(false));
        });
    }

    public get rows() { return Board._rows; }
    public get cols() { return Board._cols; }
    public cell(row: number, col: number) : Readonly<Cell> { return this._cells[row][col]; }

    public updateCell(row: number, col: number, fn: (cell: Cell) => void) {
        this.update(cells => {
            fn(cells[row][col]);
        });
    }

    private _forEachCell(cells: Cell[][], callback: (cell: Cell, row: number, col: number) => void) {
        cells.forEach((row, i) => row.forEach((cell, j) => callback(cell, i, j)));
    }
    
    public forEachCell(callback: (cell: Readonly<Cell>, row: number, col: number) => void) {
        this._cells.forEach((row, i) => row.forEach((cell, j) => callback(cell, i, j)));
    }

    public updateCells(other: Cell[][]) {
        this.update(cells => {
            this._forEachCell(cells, (cell, row, col) => cell.copy(other[row][col]));
        });
    }

    public map<T>(callback: (cell: Readonly<Cell>, row: number, col: number) => T): T[][] {
        return this._cells.map((row, i) => row.map((cell, j) => callback(cell, i, j)));
    }

    public forEachAdjacentCell(row: number, col: number, callback: (adjCell: Readonly<Cell>, adjRow: number, adjCol: number) => void) {
        if (row > 0) {
            callback(this._cells[row - 1][col], row - 1, col);
        }
        if (row < this._cells.length - 1) {
            callback(this._cells[row + 1][col], row + 1, col);
        }
        if (col > 0) {
            callback(this._cells[row][col - 1], row, col - 1);
        }
        if (col < this._cells[row].length - 1) {
            callback(this._cells[row][col + 1], row, col + 1);
        }
    }

    public forEachDiagonalCell(row: number, col: number, callback: (adjCell: Readonly<Cell>, adjRow: number, adjCol: number) => void) {
        if (row > 0 && col > 0) {
            callback(this._cells[row - 1][col - 1], row - 1, col - 1);
        }
        if (row > 0 && col < this._cells[row - 1].length - 1) {
            callback(this._cells[row - 1][col + 1], row - 1, col + 1);
        }
        if (row < this._cells.length - 1 && col > 0) {
            callback(this._cells[row + 1][col - 1], row + 1, col - 1);
        }
        if (row < this._cells.length - 1 && col < this._cells[row + 1].length - 1) {
            callback(this._cells[row + 1][col + 1], row + 1, col + 1);
        }
    }

    public findEntity(entity: Entity): Readonly<Cell> | undefined {
        var position = this.findEntityPosition(entity);
        if (position) {
            return this._cells[position.row][position.col];
        }
    }


    public findEntityPosition(entity: Entity): { row: number, col: number } | undefined {
        for (var i = 0; i < this._cells.length; i++) {
            for (var j = 0; j < this._cells[i].length; j++) {
                if (this._cells[i][j].entity === entity) {
                    return { row: i, col: j };
                }
            }
        }
    }    
}
