import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { puzzles } from "../data/puzzles";
import Tile from "./Tile";
import { Entity } from "../model/entities";
import BoardUX from "./BoardUX";

const grid: (number | Entity)[][] = [
   ["boulder", "exit", "boulder", "boulder", "boulder" ],
   ["boulder", 15, 14, 13, "boulder" ],
   ["boulder", "boulder", "boulder", 12, "boulder" ],
   ["boulder", 9, 10, 11, "boulder" ],
   ["boulder", 8, "boulder", "boulder", "boulder" ],
   ["boulder", 7, 6,  5, "boulder" ],
   ["boulder", "boulder", "boulder", 4, "boulder" ],
   ["entrance", 1, 2, 3, "boulder" ],
];

const MapList = () => {

   var location = useLocation();
   var navigate = useNavigate();

   useEffect(() => { document.title = `Lava Cave Training - Maps` });

   const base = location.state?.base ?? 1;

   function goToMap(map: number) {
      navigate(`/maps/${map}`, { state: { back: true } });
   }

   function goBack() {
      if (location.state?.back) {
         navigate(-1);
      } else if (base > 1) {
         navigate("/maps", { replace: true, state: { base: 1 } });
      } else {
         navigate("/", { replace: true });
      }
   }

   return <BoardUX>
      {Array.from({ length: 25 }, (_,  n) => 
         <Tile key={n} className={`row${Math.floor(n/5)+1} col${n % 5}`} entity="map" barText={`Stage ${n + base}`} onClick={() => goToMap(n + base)}/>
      )}
      {base == 1 && <Tile key="exit" className="row6 col3" entity="exit" barWidth={1} barText="More stages" onClick={() => navigate("/maps", { state: { back: true, base: 26 } })}/>}
      <Tile key="entrance" className="row7 col0" entity="entrance" onClick={goBack}/>
      <div className="details">
         <div className="selectStage">
            <div className="line1">Select the stage you are facing.</div>
            <div className="line2">The map will highlight hidden dangers and work with you to safely solve the level.</div>
         </div>
      </div>
   </BoardUX>;
};
 
export default MapList;
