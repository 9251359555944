import { useParams } from "react-router-dom";
import { Entity } from "../model/entities";

interface TileProps {
   className: string;
   entity?: Entity;
   closed?: boolean;
   reveal?: boolean;
   available?: boolean;
   blocked?: boolean;
   fade?: boolean;
   danger?: boolean;
   barWidth?: number;
   barText?: string;
   onClick?: ()=>void;
 }

const Tile = (props: TileProps) => {

   function onClick() {
      if (props.onClick) {
         props.onClick();
      }
   }

   function onTouchEnd(event: React.TouchEvent<HTMLDivElement>) {
      event.preventDefault();
      onClick();
   }

   var classList = [ 
      "tile",
      props.className,
      props.entity,
      props.reveal && "reveal", 
      props.available && "available",
      props.fade && "fade",
      props.danger && "danger"
   ].filter(_ => _).join(" ");

   return <div 
      className={classList}
      onTouchEnd={onTouchEnd} 
      onClick={onClick}
      >
      {props.entity ? <div className="entity"></div> : <></>}
      {(props.barText || (props.barWidth !== undefined)) ? <div className="hp">
         <div className="hpBar" style={{ width: `${(props.barWidth||0) * 100}%` }}></div>
         {props.barText ? <div className="hpText">{props.barText}</div> : <></>}
      </div> : <></>}
      {props.closed ? <div className="slate"></div> : <></>}
      {props.blocked ? <div className="no"></div> : <></>}
   </div>;
};
 
export default Tile;