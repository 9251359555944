import { Cell } from "../model/board";
import { getEntities } from "../model/entities";

function randomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function randomEmptyCell(cells: Cell[][], fixedRow?: number, fixedCol?: number): Cell {
    while (true) {
        var row = fixedRow ?? randomInt(0, cells.length - 1);
        var col = fixedCol ?? randomInt(0, cells[row].length - 1);
        var cell = cells[row][col];
        if (cell.entity === 'empty') {
            return cell;
        }
    }
}

export function generateRandomBoard(floor: number): Cell[][] {

    var cells = new Array(8).fill(0).map(_ => new Array(5).fill(0).map(_ => new Cell()));

    getEntities().forEach(([name, entity]) => {
        var count = entity.count ? entity.count(floor) : 0;
        for (var i = 0; i < count; i++) {
            var cell = randomEmptyCell(cells, entity.row, entity.col);
            cell.entity = name;
            if (entity.hp) {
                cell.hp = cell.maxHP = entity.hp(floor);
            }
            cell.open = entity.open ?? false;
        }
    });

    return cells;
}
