import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import BoardUX from "./BoardUX";
import Tile from "./Tile";

const Menu = () => {
   useEffect(() => { document.title = "Lava Cave Training"; });
   var navigate = useNavigate();

   function navigateTo(path: string) {
      navigate(path, { state: { back: true }});
   }
   return <BoardUX>
      <Tile key="puzzles" className="row2 col2" entity="boss" barText="Puzzles" barWidth={1} onClick={() => navigateTo("/puzzles")}/>
      <Tile key="freeplay" className="row4 col2" entity="boulder" barText="Free Play" barWidth={1} onClick={() => navigateTo("/freeplay")}/>
      <Tile key="map" className="row6 col2" entity="map" barText="Maps" barWidth={1} onClick={() => navigateTo("/maps")}/>
      <div className="details">
         <div className="welcome">
               <div className="line1">Welcome to Lava Cave Training!</div>
               <div className="line2">Here you can test your wits against a lava cave puzzle, play randomly generated levels, or get help exploring a real in-game level.</div>
         </div>
      </div>
   </BoardUX>

};
 
export default Menu;
